/* eslint-disable */
import { JtnUiPagination, JtnUiSideBlockHeader, JtnUiTypography } from '@jtnews/jtn-ui';

import SearchRecordsList from '../../features/archive/containers/search-records-list.container';

export const CoreSearchLogicMixin = {
  components: {
    JtnUiSideBlockHeader,
    JtnUiPagination,
    JtnUiTypography,
    SearchRecordsList
  },
  data() {
    return {
      invalidFilters: false,
      date: {
        min: this.$route.query.dateFrom || '',
        max: this.$route.query.dateTo || ''
      },
      params: {
        page: +this.$route.query.page || 1,
        pagesize: 40,
        dateFrom: this.$route.query.dateFrom || undefined,
        dateTo: this.$route.query.dateTo || undefined
      },
      pageData: {
        currentPage: parseInt(this.$route.query.page, 10) || 1
      }
    };
  },
  computed: {
    pageType() {
      return this.store.pageType;
    },
    dataBlock() {
      return this.store.archivesModule.searchResultBlock;
    },
    themeBlock() {
      return this.store.archivesModule.themeBlock;
    },
    newsData() {
      return this.dataBlock.data || [];
    },
    totalResult() {
      return this.dataBlock.meta ? this.dataBlock.meta.total : 0;
    },
    // путь, по которому достпуны данные в store
    dataPath() {
      return this.dataBlock.path || 0;
    },
    noticeMessage() {
      return this.invalidFilters ? 'Задан неверный период времени' : 'Ничего не найдено';
    },
    showedResults() {
      return !this.invalidFilters && this.totalResult && this.newsData.length > 0;
    }
  },
  methods: {
    onFilter(params) {
      this.params = {
        ...this.params,
        ...params
      };

      const routerParams = this.setParams(this.$route.query, params);
      this.updateRouterQuery(routerParams);
    },
    changePage(page) {
      this.params = {
        ...this.params,
        page
      };

      const routerParams = {
        ...this.$route.query,
        page: page > 1 ? page : undefined
      };

      this.updateRouterQuery(routerParams);
    },
    setParams(currentParams, newParams) {
      const params = {
        ...currentParams,
        page: newParams.page,
        dateFrom: newParams.dateFrom,
        dateTo: newParams.dateTo
      };

      return params;
    },
    updateRouterQuery(params) {
      const forwardedUrl = new URL(`${location.origin}${location.pathname}`);
      for (const key in params) {
        if (params[key] !== undefined) {
          forwardedUrl.searchParams.append(key, params[key]);
        }
      }
      window.location.href = forwardedUrl.toString();
    },
    invalidData(value) {
      this.invalidFilters = value;
    }
  }
};
