import { guardEmptyString } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';
// eslint-disable-next-line
import { InputHTMLAttributes } from 'vue-tsx-support/types/dom';
import { Vue } from 'vue/types/vue';

import { JtnUiDatepicker } from '@jtn-ui/components/datepicker';
import { JtnUiTypography, JtnUiBtn, JtnUiSelect } from '@jtnews/jtn-ui';
import { injectStylesMixin } from '@jtnews/shared';

import styles from './archive-filter.styles.scss?module';

type Rubric = {
  value: string;
  name: string;
};

type FilterData = {
  dateFrom?: string;
  dateTo?: string;
  rubric?: string;
};

type PublishDatesTypes = {
  dateFrom?: string;
  dateTo?: string;
};

type PropsDate = {
  min: string;
  max: string;
};

type RangeDates = (string | null)[];

type ComponentData = {
  rubricValue: string;
  rubricsList: Rubric[];
  publishDate: PublishDatesTypes;
  openedFilter: boolean;
  wrongPeriod: boolean;
  invalidDate: boolean;
  isMounted: boolean;
  isDatepickerMounted: boolean;
};

interface Events {
  submitFilter: FilterData;
}

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'ArchiveFilter',
    props: {
      date: {
        type: Object as () => PropsDate,
        default() {
          return {
            min: '',
            max: ''
          };
        }
      },
      rubrics: {
        type: Array as () => Rubric[],
        default() {
          return [];
        }
      },
      currentRubric: {
        type: String,
        default: 'all'
      },
      hasRubrics: {
        type: Boolean,
        default: false
      }
    },
    data(): ComponentData {
      return {
        publishDate: {
          dateFrom: this.date.min || undefined,
          dateTo: this.date.max || undefined
        },
        openedFilter: false,
        wrongPeriod: false,
        invalidDate: false,
        rubricsList: [
          {
            name: 'Все рубрики',
            value: 'all'
          }
        ],
        rubricValue: this.currentRubric,
        isMounted: false,
        isDatepickerMounted: false
      };
    },
    computed: {
      dates(): RangeDates {
        if (!guardEmptyString(this.date.min) || !guardEmptyString(this.date.max)) {
          return [];
        }
        return [this.date.min, this.date.max];
      }
    },
    watch: {
      currentRubric() {
        this.rubricValue = this.currentRubric;
      }
    },
    mounted() {
      this.isMounted = true;
      this.sortRubrics();
    },
    methods: {
      sortRubrics() {
        const sortedRubrics = this.rubrics
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name, 'ru'));
        this.rubricsList = [...this.rubricsList, ...sortedRubrics];
      },
      toggleFilter(event: Event) {
        event.preventDefault();
        this.openedFilter = !this.openedFilter;
      },
      setRubric(event: SyntheticEvent<InputHTMLAttributes, Event>) {
        this.rubricValue = (event.target?.value as string) || 'all';
      },
      setPublishDate(dates: RangeDates) {
        this.publishDate = {
          dateFrom: dates[0] || undefined,
          dateTo: dates[1] || undefined
        };
      },
      checkForm(event: Event) {
        event.preventDefault();

        if (this.$refs.submitButton) {
          const submitButton = this.$refs.submitButton as Vue;
          (submitButton.$el as HTMLInputElement).focus();
        }

        const params = {
          rubric: this.rubricValue !== 'all' ? this.rubricValue : undefined,
          ...this.publishDate
        };

        this.$emit('submitFilter', params);
      }
    },
    render() {
      return (
        <div class={styles.archiveFilter}>
          <form
            class={styles.form}
            autocomplete="off"
            onSubmit={event => this.checkForm(event)}
          >
            <JtnUiTypography
              class={[this.openedFilter ? styles.opened : '', styles.openBtn]}
              tag="a"
              type="font-vijet-title"
              onClick={(event: Event) => this.toggleFilter(event)}
            >
              Уточнить запрос
              <svg class={styles.icon} width="22" height="22" strokeWidth={2}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-arrow-down"></use>
              </svg>
            </JtnUiTypography>
            <div class={[this.openedFilter ? styles.opened : '', styles.filter]}>
              {this.hasRubrics && (
                <JtnUiSelect
                  class={[styles.filterItem, styles.formSelect]}
                  selected={this.rubricValue}
                  options={this.rubricsList}
                  onChange={(event: SyntheticEvent<InputHTMLAttributes, Event>) =>
                    this.setRubric(event)
                  }
                />
              )}

              {this.isMounted && (
                <JtnUiDatepicker
                  v-show={this.isDatepickerMounted}
                  class={styles.filterItem}
                  dates={this.dates}
                  isRange={true}
                  placeholder="Период"
                  disabledAfterToday={true}
                  onMounted={() => {
                    this.isDatepickerMounted = true;
                  }}
                  onChangedPeriod={(dates: RangeDates) => this.setPublishDate(dates)}
                />
              )}

              <div
                v-show={!this.isDatepickerMounted}
                class={[styles.filterItem, styles.placeholderDatepicker]}
              />

              <JtnUiBtn class={styles.submitBtn} ref="submitButton" type="submit">
                Применить
              </JtnUiBtn>
            </div>
          </form>
        </div>
      );
    }
  });
