<template>
  <div :class="$style.newsArchivePage">
    <app-layout :render-left-column="false">
      <template slot="central-column">
        <div :class="$style['wrap']">
          <jtn-ui-side-block-header :class="$style['title']" :text="pageHeader" />
          <archive-filter
            :date="date"
            :has-rubrics="true"
            :current-rubric="rubric"
            :rubrics="rubricsList"
            @submitFilter="onFilter"
          />
          <search-records-list
            v-if="showedResults"
            :records="newsData"
            :params="dataBlock.params"
          />
          <jtn-ui-typography
            v-else
            :class="$style['notice']"
            :tag="'div'"
            :type="'font-photoday-type-1'"
          >
            {{ noticeMessage }}
          </jtn-ui-typography>
          <jtn-ui-pagination
            v-model="pageData"
            v-if="showedResults"
            v-show="pageData.numPages && pageData.numPages > 1"
            :class="$style['pagination']"
            :items-per-page="params.pagesize"
            :total-items="totalResult"
            @change="changePage(pageData.currentPage)"
          />
        </div>
      </template>

      <template slot="right-column">
        <app-right-column />
      </template>
    </app-layout>
  </div>
</template>

<script>
  import { ACTUAL_RUBRICS_INFO, allRubricsIds } from '@jtnews/shared/data';

  import AppLayout from '../../../layout/containers/app-layout';
  import AppRightColumn from '../../../layout/containers/app-right-column';
  import { ArchiveFilter } from '../components/archive-filter';

  import { PageAnalyticsMixin } from '../../../shared/mixins/page-analytics';
  import { WindowScrollMixin } from '../../../shared/mixins/window-scroll.mixin';
  import { CoreSearchLogicMixin } from '../../../shared/mixins/core-search-logic.mixin';

  export default {
    name: 'ArchivePage',
    components: {
      AppLayout,
      AppRightColumn,
      ArchiveFilter
    },
    mixins: [PageAnalyticsMixin, WindowScrollMixin, CoreSearchLogicMixin],
    data() {
      return {
        rubricsList: [],
        params: {
          page: +this.$route.query.page || 1,
          pagesize: 40,
          rubric: undefined,
          dateFrom: this.$route.query.dateFrom || undefined,
          dateTo: this.$route.query.dateTo || undefined
        }
      };
    },
    computed: {
      pageHeader() {
        if (this.rubric && this.rubric !== 'all') {
          return `Все новости рубрики ${this.getRubricNameByPath(this.rubric)}`;
        }

        return 'Все новости';
      },
      rubric() {
        return this.getRubricParams(this.$route) || 'all';
      }
    },
    beforeMount() {
      const rubrics = ACTUAL_RUBRICS_INFO || {};

      this.rubricsList = this.extendRubricObj(rubrics);
      this.params.rubric = this.getRubricParams(this.$route);
    },
    methods: {
      onFilter(params) {
        this.params = {
          ...this.params,
          ...params
        };

        const routerPath = this.$route.path;
        const routerQuery = this.setParams(this.$route.query, params);

        routerQuery.rubric = params.rubric;

        this.updateRouterQuery(routerQuery, routerPath);
      },
      extendRubricObj(rubrics) {
        const rubricsObj = allRubricsIds.map(rubricId => {
          if (rubrics[rubricId] !== undefined) {
            const rubric = rubrics[rubricId];
            return {
              ...rubric,
              id: rubricId,
              name: rubric.value,
              value: rubricId
            };
          }
        });

        return rubricsObj.filter(rubric => rubric !== undefined);
      },
      getRubricNameByPath(rubricPath) {
        const rubric = this.rubricsList.find(r => r.id === rubricPath);
        if (rubric !== undefined) return rubric.name;
        return '';
      },
      getRubricParams(route) {
        return route.query.rubric || undefined;
      }
    }
  };
</script>

<style lang="scss" module>
  @import 'styles';
  @import '../../../styles/news-archive-page';
</style>
